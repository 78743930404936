<template>
    <div>
    <div class="topo" v-if="remote.coordenadas_ativo">
        <!-- <div v-if="remote.coordenadas.event_br"> -->
        <h4>{{ remote.coordenadas.event_br }}</h4>
        <img class="img-hora" :src="`${$url}/storage/${remote.coordenadas.img}`" v-if="remote.coordenadas.img" />

        <div class="countdown" v-if="remote.coordenadas.timer">
            <div class="content-inicio">
                <div class="text"></div>
                <div class="datee">{{ remote.coordenadas.inicio | formatDate }}</div>
            </div>
            <VueCountdownTimer :start-time="inicio" :end-time="fim" :interval="1000" :start-label="$t('eventos.inicia')"
                :end-label="$t('eventos.termina')" label-position="begin" :end-text="$t('eventos.finalizado')"
                :day-txt="'d'" :hour-txt="'h'" :minutes-txt="'m'" :seconds-txt="'s'">
                <template slot="start-label" slot-scope="scope">
                    <span style="color: red" v-if="scope.props.startLabel !== '' &&
                        scope.props.tips &&
                        scope.props.labelPosition === 'begin'
                        "><strong>{{ scope.props.startLabel }}:</strong></span>
                    <strong v-if="scope.props.endLabel !== '' &&
                        !scope.props.tips &&
                        scope.props.labelPosition === 'begin'
                        ">{{ scope.props.endLabel }}:</strong>
                </template>

                <template slot="countdown" slot-scope="scope">
                    <span>{{ scope.props.days }}</span>{{ scope.props.dayTxt }} <span>{{ scope.props.hours }}</span>{{
                        scope.props.hourTxt }} <span>{{ scope.props.minutes }}</span>{{ scope.props.minutesTxt }}
                    <span>{{ scope.props.seconds }}</span>{{ scope.props.secondsTxt }}
                </template>

                <template slot="end-label" slot-scope="scope">
                    <span v-if="scope.props.startLabel !== '' &&
                        scope.props.tips &&
                        scope.props.labelPosition === 'end'
                        "><strong>{{ scope.props.startLabel }}:</strong></span>
                    <span v-if="scope.props.endLabel !== '' &&
                        !scope.props.tips &&
                        scope.props.labelPosition === 'end'
                        "><strong>{{ scope.props.endLabel }}:</strong></span>
                </template>

                <template slot="end-text" slot-scope="scope">
                    <span><strong>{{ scope.props.endText }}</strong></span>
                </template>
            </VueCountdownTimer>
        </div>

        <div class="list-group" v-if="remote.coordenadas.coords_ativo">
            <div class="list-group-item" v-for="(c, index) in remote.coordenadas.coords" :key="index">
                <div class="coord-content">
                    <div class="coord-texts">
                        <div class="coord-text" :style="c.copy
                                ? 'text-decoration: line-through;'
                                : ''
                            ">
                            {{ c.coord }}
                        </div>
                    </div>
                    <div class="coord-btn">
                        <vue-flip active-click width="100px" height="45px" class="btn-content" v-model="c.copy">
                            <template v-slot:front>
                                <div class="btn" @click="getCopy(c)">
                                    {{ $t('locais.copiar') }}
                                </div>
                            </template>
                            <template v-slot:back>
                                <div class="btn" @click="getCopy(c)">=)</div>
                            </template>
                        </vue-flip>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="remote.coordenadas.ads_ativo">
        <a :href="remote.coordenadas.url" target="_blank" >
            <img class="img-hora" :src="`${$url}/storage/${remote.coordenadas.img2}`" v-if="remote.coordenadas.img2" />
        </a>
    </div>
    </div>
</template>

<script>
import VueFlip from 'vue-flip'
import moment from 'moment'
import 'moment/dist/locale/pt-br.js'
import 'moment-timezone'
moment.locale('pt-br')

export default {
    props: ['remote'],
    components: { VueFlip },
    computed: {
        inicio() {
            const dataAtual = new Date(new Date(`${this.remote.coordenadas.inicio} -0300`).toLocaleString("en-US", { timeZone: new window.Intl.DateTimeFormat().resolvedOptions().timeZone }))
            return moment(dataAtual).tz(new window.Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm:ss')
        },
        fim() {
            const dataAtual = new Date(new Date(`${this.remote.coordenadas.fim} -0300`).toLocaleString("en-US", { timeZone: new window.Intl.DateTimeFormat().resolvedOptions().timeZone }))
            return moment(dataAtual).tz(new window.Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm:ss')
        }
    },
    filters: {
        formatDate(x) {
            const dataAtual = new Date(new Date(`${x} -0300`).toLocaleString("en-US", { timeZone: new window.Intl.DateTimeFormat().resolvedOptions().timeZone }))
            return moment(dataAtual).tz(new window.Intl.DateTimeFormat().resolvedOptions().timeZone).format('ddd, DD MMM - HH:mm')
        },
        formatMonth(x) {
            return moment()
                .set('month', x - 1)
                .format('MMMM')
        }
    },
    methods: {
        convertTZ(date, tzString) {
            return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }))
        },
        toTimestamp(strDate) {
            var datum = Date.parse(strDate)
            return datum / 1000
        },
        getCopy(coord) {
            if (!coord.copy) {
                this.$copyText(coord.coord).then(
                    () => null,
                    () => alert('Erro')
                )

            } else {
                coord.copy = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.content-inicio {
    display: flex;
    justify-content: center;
}
.countdown {
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
}
.img-hora {
    margin-bottom: 10px;
    border-radius: 5px;
}

.list-group {
    list-style: none;
    margin: 0 0 10px 0;
}

.coord-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-size: 14px;
}

.list-group-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: lavender;

    small {
        font-size: 11px;
    }

    .remove {
        background-color: #b73b43;
        border: 1px solid #b73b43;
        color: #fff;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.topo {
    margin-top: 10px;
}
</style>